import { createStore } from 'vuex'
import basicData from '@/assets/data/basic-data.js'
import loveData from '@/assets/data/love-data.js'
import richData from '@/assets/data/rich-data.js'
import makData from '@/assets/data/mak-data.js'

export default createStore({
  state: {
    // 슬라이드 단계
    step: -1,

    // 보여줄 탭 이름 (home, basic, love, rich, mak)
    showTab : "home",

    // 데이터
    data: {
      basic: basicData,
      love: loveData,
      rich: richData,
      mak: makData,
    },

    // 왼손
    leftHand: false,

    // 모달
    openModal: false,
    openPreview: false,
    showBook:false,

    // 선택항목
    cardPick: [9,9,9,9,9,9,9]

  },

  mutations: {
    // 왼손모드
    toggleLeftHand(state){
      state.leftHand = !state.leftHand;
    },
    // 카드 픽
    updatePick(state, {i, step}){
      state.cardPick[step]=i
    },
    nextStep(state){
      state.step+=1;
    },
    priorStep(state){
      state.step-=1;
    },

    // 모달
    modalHandler(state){
      state.openModal = !state.openModal
    },
    previewHandler(state){
      state.openPreview = !state.openPreview
    },
    bookHandler(state){
      state.showBook = !state.showBook
    },

    // 탭 이동
    toHome(state){
      state.step = -1;
      state.leftHand= false;
      state.cardPick = [9,9,9,9,9,9,9];
      state.showTab = "home";
    },
    toBasic(state){
      state.step = 0;
      state.leftHand= false;
      state.cardPick = [9,9,9,9,9,9,9];
      state.showTab = "basic";
    },
    toLove(state){
      state.step = 0;
      state.leftHand= false;
      state.cardPick = [9,9,9,9,9,9,9];
      state.showTab = "love";
    },
    toRich(state){
      state.step = 0;
      state.leftHand= false;
      state.cardPick = [9,9,9,9,9,9,9];
      state.showTab = "rich";
    },
    toMak(state){
      state.step = 0;
      state.leftHand= false;
      state.cardPick = [9,9,9,9,9,9,9];
      state.showTab = "mak";
    },
  },
})
