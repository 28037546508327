export default [
  {
    id:0,
    'deco': '특별한 막쥔손금',
    'main': '막쥔손금 전용',
    'tab': 'mak',
    'title': '막쥔손금',
  },
  {
    id:1,
    'text': '막쥔손금이 어떤 모습인가요?',
    'help': '* 감정선 : 새끼손가락 밑에서 검지 쪽으로 향하는 선 <br> * 두뇌선 : 검지와 엄지 사이에서 손바닥을 가로지르는 선',
    'opts': ['매끈한 하나의 선', '감정선과 두뇌선이 남아있음', '두뇌선이 남아있음', '감정선이 남아있음','부드러운 물결모양'],
    'ans': [
      {'keyword':'끝장을 보는 워커홀릭', 'more':'고지식하고 무미건조할 수 있으며, 하나에 몰두하면 외부 상황에 아랑곳하지 않고 끝장을 보는 성격입니다. 사회, 경제적으로 성공을 이룬 워커홀릭이나 화려한 싱글에게 많이 보이는 손금입니다.'},
      {'keyword':'조화로운 능력자', 'more':'공감 능력이 뛰어나고 사고가 유연해서 사람들 속에 잘 녹아들 수 있습니다. 뛰어난 협업능력과 막쥔 손금의 집중력을 기반으로 독립적으로도, 조직 내에서도 모두 크게 활약할 수 있습니다.'},
      {'keyword':'비상한 두뇌 능력', 'more':'갈라진 두뇌선의 영향으로 어떤 내용이든 빠르게 흡수하며, 뛰어난 말재주를 통해 사람들을 설득하는 능력이 있습니다. 비상한 두뇌의 소유자이면서 융통성 있고 임기응변 능력도 탁월합니다. 한 편, 일자 감정선의 영향으로 집착이 강하고 고집이 세며 감정 표현이 서툰 편입니다.'},
      {'keyword':'강인하고 믿음직한 사람', 'more':'자기 통제력이 강하며 수단과 방법을 가리지 않고 목적을 달성하는 집념의 아이콘 같은 사람입니다. 발달한 감정선의 영향으로 따뜻하고 열정적이며 애정 표현도 풍부합니다. 직선형 두뇌선의 영향으로 성격이 단순하며, 일편단심으로 한눈을 팔지 않는 믿음직한 사람입니다.'},
      {'keyword':'우아한 연예인', 'more':'막쥔 손금의 모양이 구불구불하면, 무미건조하고 딱딱한 일반적인 막쥔 손금과 정반대로 성격과 사고가 부드럽고 유연합니다. 연예인이나 예술가에게 많이 보이는 손금으로, 예술적 안목이 높은 경우가 많습니다.'}
    ]
  },
  {
    id:2,
    'text': '결혼선이 몇 개인가요?',
    'help' : '* 결혼선 : 새끼손가락 아래 뻗은 가로선',
    'opts': ['선명함 / 1~2개', '선명함 / 3개이상', '연함 / 많음', '잘 안보이거나 없음'],
    'ans': [
      {'keyword':'최고의 배우자감', 'more':'“연애=결혼”이라고 여기고 결혼하면 상대만 바라보고 가정에 충실한 최고의 배우자감입니다. 배려심이 많고 따뜻한 성격으로, 신뢰를 바탕으로 만남을 안정적으로 이어갑니다.'},
      {'keyword':'두 사랑', 'more':'결혼을 생각할 만큼 깊은 인연이 두 명 이상 있거나, 재혼의 가능성도 있습니다. 또는 한 사람과 헤어졌다가 다시 만날 수도 있습니다. 어떤 경우이든 살아가면서 인생에 큰 영향을 끼치는 인연이 두 번 이상 있을 것입니다.'},
      {'keyword':'친절한 바람둥이', 'more':'섬세하고 다정다감한 성격으로, 항상 주변에 이성 친구가 많습니다. 이성의 마음을 쉽게 사로잡고 본인도 쉽게 반하는 바람둥이입니다.'},
      {'keyword':'결혼을 왜 하죠', 'more':'결혼에 대한 의욕이나 동경이 없는 상태입니다. 결혼선이 없다고 결혼할 수 없는 것은 아니며, 단지 지금으로서는 결혼에 관한 생각이 없는 상태를 의미합니다. 이미 결혼해서 안정적으로 행복한 결혼 생활을 하고 있을 때에도 결혼선이 연해질 수 있습니다.'}
    ]
  },
  {
    id:3,
    'text': '결혼선 중 가장 선명한 선의 위치가 어딘가요?',
    'help' : '* 결혼선 : 새끼손가락 아래 뻗은 가로선',
    'opts': ['낮음', '중간', '높음', '해당없음'],
    'ans': [
      {'keyword':'과속 스캔들', 'more':'일반적인 결혼 연령보다 일찍 결혼합니다. (단, 여기서 "결혼"이라 함은 "결혼을 생각할만큼 깊은 인연"으로 대체해서 이해해도 됩니다.)'},
      {'keyword':'적령기에 결혼', 'more':'일반적인 결혼 연령에 결혼합니다. (단, 여기서 "결혼"이라 함은 "결혼을 생각할만큼 깊은 인연"으로 대체해서 이해해도 됩니다.)'},
      {'keyword':'늦은 결혼', 'more':'일반적인 결혼 연령보다 늦게 결혼하게 됩니다. (단, 여기서 "결혼"이라 함은 "결혼을 생각할만큼 깊은 인연"으로 대체해서 이해해도 됩니다.)'},
      {'keyword':'', 'more':''}
    ]
  },
  {
    id:4,
    'text': '금성대의 모양이 어떤가요?',
    'help': '* 금성대 : 중지와 약지 하단을 감싸는 활모양의 선',
    'opts': ['깔끔한 활 모양', '가운데가 끊어짐', '토막 토막 끊어짐','여러 줄로 이루어짐', '해당없음'],
    'ans': [
      {'keyword':'아름다운 인기쟁이', 'more':'감수성과 미적 감각이 뛰어나며, 평범하지 않은 화려하고 열정적인 연애를 합니다. 이성에 대해 어릴 때부터 관심이 많고, 성을 일찍 깨우치는 경향이 있습니다. 생각하는 것을 좋아해서 가만히 있을 때도 무언가 골똘히 생각하고 있습니다. 자기만의 독특한 세계관이 있는 뇌섹남, 뇌섹녀이며 이성에게 인기가 많습니다.'},
      {'keyword':'매니아층 보유', 'more':'대중적으로 인기 있는 스타일은 아니지만, 소수의 매니아층을 홀딱 반하게 하는 개성 있는 매력의 소유자입니다. 감성이 풍부하고 온화한 심성을 가지고 있어 사람들이 많이 호감을 느낍니다.'},
      {'keyword':'섬세한 변태', 'more':'금성대가 지저분하게 토막토막 끊어져 있는 경우, 자극적인 것을 좋아하고 성적 취향이 독특한 경향이 있어 “변태 손금”이라고도 합니다. 그러나 감수성이 풍부하고 분위기 메이커이며 자신을 세련되게 잘 꾸며서 인기가 많습니다. 한편, 싫증을 잘 내서 연애가 오래가지 못하고 헤어져도 곧바로 다른 애인이 생기거나 여러 사람을 동시에 만나기도 하는 바람둥이입니다. '},
      {'keyword':'카사노바', 'more':'미적 감각이 뛰어나고 감정이 풍부하며, 카사노바와 같은 마성의 매력의 소유자입니다. 가만히 있어도 유혹적인 분위기를 풍기며, 별 노력 없이도 말이나 행동으로 상대를 홀릴 수 있는 능력이 있습니다. 본인도 이성에게 성적으로 관심이 많으며, 연인관계가 깔끔하지 못한 경향이 있습니다.'},
      {'keyword':'', 'more':''}
    ]
  },
  {
    id:5,
    'text': '운명선이 어디서 시작하나요?',
    'help': '* 운명선 : 출발점에 상관없이 중지 쪽으로 향하는 선',
    'opts': ['손목 위 정중앙', '생명선 안쪽', '생명선 안쪽 위','생명선 위', '손날쪽 손바닥 하단','해당없음'],
    'ans': [
      {'keyword':'야무진 모범생', 'more':'독립심이 강해 무엇이든 스스로 결정하고 노력하는 강인한 정신력의 소유자입니다. 직업운을 타고나서 노력에 따라 대성할 수 있는 손금으로, 모범적인 학창 시절을 보내고 대기업, 관공서와 같은 곳에 몸을 담는 경우가 많습니다. 부모님 곁을 떠나 생활하는 경우가 많으며, 가족이나 친척의 생계를 책임지기도 합니다.'},
      {'keyword':'가족의 은혜', 'more':'가족, 고향을 의미하는 금성구에서 운명선이 시작하면 집안의 도움을 받으면서 운이 트입니다. 가족 사업을 이어받거나, 재산을 상속받거나, 좋은 짝을 소개받는 등 가족이나 친지에게 큰 은혜를 입을 수 있습니다.'},
      {'keyword':'사랑받는 애제자', 'more':'경쟁심이 강하며 야무진 성격으로 붙임성이 좋아 윗사람에게 총애를 받는 유형입니다. 어디를 가든 사랑받으며, 만나면 왠지 도움을 주고 싶은 마음이 들게 되는 복 받은 손금입니다. 배우나 음악인 등과 같은 불안정한 직업군에서 많이 나타납니다.'},
      {'keyword':'자수성가 노력가', 'more':'다른 사람의 도움 없이 혼자 치열하게 노력해서 성공을 이루는 자수성가 유형입니다. 성공의 방식은 의사, 변호사, 회계사처럼 취득하기 굉장히 어렵고 오래 걸리는 전문자격증 공부를 통해 목표를 달성하는 경우가 많습니다. 또는 혼자 힘으로 사업을 번성시키기도 합니다.'},
      {'keyword':'타인의 도움', 'more':'창의적이고 자유분방한 성격으로, 구속이 없는 자유로운 환경에 있게 되면 물 만난 물고기처럼 재능을 꽃피우고 활약할 수 있습니다. 집안사람들보다는 타인과의 연이 깊으므로, 고향을 벗어나 활동 반경을 넓히면 운이 확 트입니다. 새로운 곳에 쉽게 적응할 수 있고 사람들이 도와줘서 일이 잘 풀리게 됩니다.'},
      {'keyword':'', 'more':''},
    ]
  },
  {
    id:6,
    'text': '재물선이 몇 개 있나요?' ,
    'help' : '* 재물선 : 약지 밑에 있는 세로선',
    'opts': ['선명함 / 1개', '선명함 / 2~3개', '연한 선이 많음', '없음'],
    'ans': [
      {'keyword':'하나만 판다', 'more':'주요 수입원이 하나뿐입니다. 하지만 그 하나의 재물선이 선명하다면 현명한 선택과 집중을 통해 모든 힘을 한 일에 집중해서 큰돈을 벌 수 있습니다. 재물선이 길고 굵게 뻗어 있을수록 경제적으로 풍요로운 생활을 누릴 수 있습니다. 하지만 만약 하나뿐인 재물선이 연하다면 경제적인 어려움이 생길 수도 있습니다.'},
      {'keyword':'투잡 쓰리잡', 'more':'다양한 수익원을 통해 돈을 벌어 부를 이룰 수 있습니다. 부동산 임대, 인세 수익과 같은 안정적인 자동 수익이 생길 가능성이 큽니다.'},
      {'keyword':'성장 중인 유망주', 'more':'재물선이 연하고 많은 사람은 다재다능하고 이것저것 배우기를 좋아하는 사람입니다. 구상하는 일이 많아 늘 바쁘지만 실질적인 성과는 내지 못하고 있는 상태입니다. 하지만 지금처럼 에너지 넘치게 도전하고 배우다 보면 재물선들이 개수는 줄어들고 선명해질 것입니다.'},
      {'keyword':'돈이 안모여요', 'more':'재물선이 연해서 일을 열심히 해도 돈을 잘 벌지 못하고 인정도 받지 못한다. 하지만 현재 상황에 비관하지 않고 목표를 분명히 하고 재산을 모으기 위해 노력하면 재물선이 선명해지고 재물운이 좋아질 수 있다.'}
    ]
  },

]