<template>
  <div class="view-bg">
    
    <span class="deco-text intro-title">{{IntroData[0].title}}</span>
    <img :src="getImagePath" class="main-img" alt="손금 사진">
  
    <p>자주 쓰는 손을 골라주세요</p>
    <div class="flex-container">
      <div class="toggle-label">
        <p>왼손</p>
        <input id="toggle" type="checkbox" v-model="leftHand" @change="toggleLeftHand" /><input type="checkbox" id="toggle" hidden> 
        <p>오른손</p>
      </div>
    </div>
  
    <div class="flex-container">
      <div class="my-button back-btn" @click="priorStep">
        <span>돌아가기</span>
      </div>
      <div class="my-button start-btn" @click="nextStep">
        <span>시작하기</span>
      </div>
    </div>
  

  </div>



</template>

<script>
import {mapMutations, mapState} from 'vuex';

export default {

  methods: {
    ...mapMutations(['priorStep','nextStep','toggleLeftHand']),
  },

  computed:{
    ...mapState(['showTab', 'data','leftHand']), 
    IntroData() {
      return this.data[this.showTab];
    },
    getImagePath() {
      return require(`@/assets/intro-img/${this.showTab}.png`);
    },
  }
}
</script>

<style scoped>

.main-img{
  width: 200px;
  object-fit:fill;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem;
  opacity: 80%;
}

/* 왼손모드 버튼 */
.toggle-label{
  display: flex;
  gap: 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1.5rem;
}
#toggle{
  width:60px;  height:30px;
  border-radius:40px;
  position: relative;
  -webkit-appearance: none;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.4);
  background: var(--beige)
}
#toggle:checked{
  background: var(--milkyGreen);
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0, 0.4);
}
#toggle:before {
  content:"";
  padding:10px;
  top:5px;  left:6px;
  position:absolute;
  border-radius:40px;
  background: var(--lightBeige);
  box-shadow: -2px 2px 8px rgba(0, 0, 0, 0.2), -1px 1px 2px rgba(0, 0, 0, 0.3), inset 1px 1px 1px #ffffff; -webkit-transition: all 1s;
}
#toggle:checked:before {
  left:35px;
  background:#f1f1f1;
}
</style>