<template>
  <div class="black-bg">

    <!-- 닫기버튼 -->
    <div class="circle">
      <span class="close-btn" @click="previewHandler">X</span>
    </div>

    <!-- 미리보기 이미지 -->
    <div class="modal-contents item">
      <img class="modal-img" src="@/assets/book/1.jpg" v-if="imgNum==1">
      <img class="modal-img" src="@/assets/book/2.jpg" v-if="imgNum==2">
      <img class="modal-img" src="@/assets/book/3.jpg" v-if="imgNum==3">
      <img class="modal-img" src="@/assets/book/4.jpg" v-if="imgNum==4">
      <img class="modal-img" src="@/assets/book/5.jpg" v-if="imgNum==5">
      <img class="modal-img" src="@/assets/book/6.jpg" v-if="imgNum==6">
      <img class="modal-img" src="@/assets/book/7.jpg" v-if="imgNum==7">
      <img class="modal-img" src="@/assets/book/8.jpg" v-if="imgNum==8">
      <img class="modal-img" src="@/assets/book/9.jpg" v-if="imgNum==9">
      <img class="modal-img" src="@/assets/book/10.jpg" v-if="imgNum==10">
      <img class="modal-img" src="@/assets/book/11.jpg" v-if="imgNum==11">
      <img class="modal-img" src="@/assets/book/12.jpg" v-if="imgNum==12">
      <img class="modal-img" src="@/assets/book/13.jpg" v-if="imgNum==13">
      <img class="modal-img" src="@/assets/book/14.jpg" v-if="imgNum==14">
    </div>

    <!-- 이전, 페이지번호, 이후 -->
    <div class="modal-footer-container">
      <div class="modal-footer">
        <div class="arrow prev item">
          <span @click="priorStep">◀</span>
        </div>
        <div class="page-num">
          <p style="font-size:1.5rem;">{{imgNum}}/14</p>
        </div>
        <div class="arrow next item" >
          <span @click="nextStep">▶</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueLazyload from 'vue-lazyload'; // vue-lazyload 임포트
import { mapMutations } from 'vuex';

export default {
  data(){
    return {
      imgNum: 1
    }
  },
  methods:{
    ...mapMutations(['previewHandler']),
    priorStep(){
      if (this.imgNum==1){
        return this.imgNum = 1;
      } else {
        return this.imgNum -= 1;
      }
    },
    nextStep(){
      if (this.imgNum==14){
        return this.imgNum = 14;
      }
      else {
        return this.imgNum += 1;
    }
  }
},
  // directives: {
  //   lazy: VueLazyload.directive, // v-lazy 디렉티브 설정
  // },
}
</script>

<style scoped>
.item{
  margin-top:auto;
  margin-bottom:auto;
}
.arrow{
  color: var(--lightBeige);
}

.modal-contents{
  width:100%;
  max-height: 90vh;
  align-items: center;
}
.modal-img {
  max-height: 85vh; /* 이미지 높이를 부모 요소 높이의 90%로 맞춤 */
  max-width: 100%; /* 이미지의 최대 가로 너비를 부모 요소에 맞춤 */
  width: auto; /* 이미지의 너비는 자동으로 조정됨 */
  /* margin-top:auto; */
}


.arrow {
  color: var(--lightBeige);
  cursor: pointer;
  font-size: 1.5rem;
}
.arrow:hover{
  font-weight: bold;
  color: var(--warm);
}


.circle{
  background-color: rgb(68, 68, 68);
  margin: 0.5rem auto;
  width:2.5rem;
  height:2.5rem;
  border-radius: 50%;
  position: relative;
}
.close-btn{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--lightBeige);
}


.modal-footer-container{
  display: flex;
  margin-top:0.5rem;
}
.modal-footer{
  margin-left:auto;
  margin-right: auto;
  display: flex;
  gap:2.5rem;
}
.page-num {
  color: rgb(219, 219, 219);
  font-size: 5rem;
}

</style>