<template>

<transition name="fade">
  <Modal v-if="openModal"/>
</transition>

<transition name="fade">
  <BookPreview v-if="openPreview"/>
</transition>


<!-- 헤더 -->
<div class="header-container">
  <div class="header">
    <div class="flex-start gray" @click="toHome">
      <!-- <span class="material-symbols-outlined" >home</span> -->
      <span>🏠 홈으로</span>
    </div>
    <div class="flex-center" @click="toHome">
      <img src="@/assets/logo.png" class="logo-img" alt="손금 쇼미더손금">
      <span class="title">쇼미더손금</span>
    </div>
    <div class="flex-end gray" @click="modalHandler">
      <!-- <span class="material-symbols-outlined" >help</span> -->
      <span>🌠 도움말</span>
    </div>
  </div>
</div>

<!-- 3등분 -->
<div class="three-col-container-container">
  <div class="three-col-container">
    <!-- 중앙 -->
    <div class="app-container three-col">
        <div class="main-container">
          <!-- 쿠팡 광고-->
          <div class="mt-3">
            <a href="https://link.coupang.com/a/9QwbA" target="_blank" referrerpolicy="unsafe-url"><img src="https://ads-partners.coupang.com/banners/689970?subId=&traceId=V0-301-371ae01f4226dec2-I689970&w=320&h=50" alt=""></a>
          </div>

          <Home
            v-if="step==-1"
          />
          <Intro
            v-if="step==0"
          />
          <Slides
            v-if="step>0&&step<7"
          />
          <End
            v-if="step==7"
          />

        <div class="coupang-ad-container ad-shadow m-4">
          <!-- <iframe src="https://ads-partners.coupang.com/widgets.html?id=690129&template=banner&trackingCode=AF5629648&subId=&width=320&height=50" width="320" height="50" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe> -->
          <iframe src="https://ads-partners.coupang.com/widgets.html?id=690125&template=carousel&trackingCode=AF5629648&subId=&width=320&height=320&tsource=" width="320" height="320" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
        </div>

          <!-- 카카오 정사각형 광고-->
          <!-- <div class="mt-4">
            <ins class="kakao_ad_area" style="display:none;"
            data-ad-unit = "DAN-N7xSxFEoUUWSdg3Y"
            data-ad-width = "300"
            data-ad-height = "250"></ins>
          </div> -->

          <!-- 내 책 광고 -->
          <!-- <div class="book-ad-container" @click="bookHandler" >
            <div class="book-ad" v-if="!showBook">
              <img src="@/assets/book.png" alt="어쩐지 손금 책">
            </div >
            <div class="book-explain" v-if="showBook">
              <div class="opacity-box">
                <div class="book-explain-contents">
                  <h2>어쩐지 손금 <span style="font-size:1rem;">(57p)</span></h2>
                  <img src="@/assets/book-ad.png" alt="" style="width:170px;">
                  <p></p>
                  <p>나는 왜 이럴까?</p>
                  <p>너는 왜 그럴까?</p>
                  <div class="mt-4">
                    <span class="my-button" @click="previewHandler">미리보기</span>
                    <span class="my-button" style="margin-left:1rem;" @click="buyBook">구매하기</span>
                  </div>
                </div>
              </div>
            </div>

          </div> -->


        </div>
    </div>

  </div>
</div>
</template>

<script>
import Modal from '@/components/Modal.vue'
import Home from '@/components/Home.vue'
import Intro from '@/components/Intro.vue'
import Slides from '@/components/Slides.vue'
import End from './components/End.vue'
import BookPreview from '@/components/BookPreview.vue'
// import Discuss from '@/components/Discuss.vue'
import {mapState,mapMutations} from 'vuex'

export default {
  components: {
    Modal,
    Home,
    Intro,
    Slides,
    End,
    BookPreview,
    // Discuss
  },
  computed: {
    ...mapState(['step', 'openModal', 'openPreview', 'showBook', 'showTab']),
  },
  methods: {
    buyBook(){
      window.open('https://www.yes24.com/Product/Goods/122092609', '_blank');
    },
    ...mapMutations(['modalHandler', 'bookHandler','previewHandler','toHome', 'toBasic', 'toLove', 'toRich', 'toMak']),




  },

}
</script>

<style>
@import 'style.css';  
/* pc-ad */
.pc-ad-area{
  /* background-color: antiquewhite;
  border: 2px solid red; */
  display: flex;
  flex-direction: column;
}
.pc-ad-1{
  margin-left: auto;
  padding-right:2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.pc-ad-2{
  margin-right: auto;
  padding-left:2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (max-width: 930px) {
  .three-col{
    /* flex-basis: 100%; */
    /* width: 100%; */
    margin:0;
    padding:0;
  }
  .pc-ad-1, .pc-ad-2{
    visibility: hidden;
    display: none;
  }
  .pc-ad-area{
    height: 0;
  }
  .three-col-container{
    margin-top: 0;
  }
}
/* 3개 전체의 폭 */
.three-col-container{
  width: 90vw;
  max-width: 1200px;
  margin-top: 1rem;
  margin-left:auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
}
/* @media(max-width:2000px){
  .three-col-container{
    width:90vw;
  }
} */
/* navbar */
.header-container{
  width:100%;
  height: 110px;
  background-color: var(--lightBeige);
  display: flex;
}
.header{
  margin-top:auto;
  margin-bottom:auto;
  width: 80vw;
  max-width:1130px;
  min-width: 320px;
  display: flex;
  align-items: center;
  font-size:1.3rem;
  margin-left: auto;
  margin-right: auto;
}
.logo-img{
  width: 2.5rem; 
  object-fit: cover;
  margin-right: 0.8rem;
}
.title{
  font-family: 'East Sea Dokdo', cursive;
  font-size: 2.5rem;
  color:rgb(50, 50, 50);
}

/* 모달 fade */
.fade-enter-from{
  opacity: 0;
}
.fade-enter-active{
  transition: all 0.5s;
}
.fade-enter-to{
  opacity:1;
}
.fade-leave-from{
  opacity: 1;
}
.fade-leave-active{
  transition: all 0.5s;
}
.fade-leave-to{
  opacity:0;
}
/* discuss */
.discuss-container{
  margin: 2rem;
}
/* 하단 광고 */
.coupang-container{
  display: flex;
  flex-direction: column;
}
.coupnag{
  margin-left: auto;
  margin-right: auto;
}
</style>
