<template>

  <div class="slide-container pt-4" >
    <div class="slide-button prev-slide">
      <span class="material-symbols-outlined" @click="priorSlide">arrow_back_ios_new</span>
    </div>
    <div class="slide-button next-slide">
      <span class="material-symbols-outlined" @click="nextSlide">arrow_forward_ios</span>
    </div>
  
    <div class="main-contents">
      <h4 class="mt-3">{{step}}. {{slideData[step].text}}</h4>
      <span class="mt-3 help-text" v-html="slideData[step].help"></span>
      <div class="card-container mt-3">
        <div 
          class="card slide-card"
          v-for="(opt, i) in slideData[step].opts" :key="i"
          :class="{ selected: cardPick[step] === i}"
          @click="saveCardNumber(i, step)"
        >
          <span class="option-text">{{ i+1 }}. {{ opt }}</span>
          <img 
            :src="getSlideImagePath(i)"
            @load="applyStyle" 
            alt="손금 이미지"
          />
        </div>
      </div>
      <div class="dots mt-4">
        <div
          v-for="i in slideNum"
          :key="i"
          :class="['nav-dot', { 'active-dot': i === step }]"
          @click="goToSlide(i)"
        ></div>
      </div>
    </div>
  </div>
  

  <!-- 쿠팡 광고 320x100 -->
  <!-- <div class="mt-4">
    <a href="https://link.coupang.com/a/6DyDN" target="_blank" referrerpolicy="unsafe-url"><img src="https://ads-partners.coupang.com/banners/696141?subId=&traceId=V0-301-50c6c2b97fba9aee-I696141&w=320&h=100" alt=""></a>
  </div>   -->

  <!-- <p>{{cardPick}}</p>
  <p>{{ step }}</p> -->
  </template>
  
  <script>
  import { mapState, mapMutations } from 'vuex'; 

  export default {
    data() {
      return {
        slideNum: 6, // Total number of slides
        activeSlide: 1, // Active slide index,
      };
    },

    computed: {
      ...mapState(['step','showTab','data','leftHand','cardPick']),
      slideData() {
        return this.data[this.showTab];
      },
    },
    methods: {
      ...mapMutations(['nextStep', 'priorStep', 'updatePick']),
      applyStyle(event) {
        event.target.style.transform = this.leftHand ? 'scaleX(1)' : 'scaleX(-1)';
      },
      getSlideImagePath(i) {
        return require(`@/assets/${this.showTab}-hands/${this.step}/${i + 1}.png`);
      },

      saveCardNumber(i, step) {
        // this.bindingStyleRemove();
        this.updatePick({ i, step });
        this.nextStep();
        this.scrollToTop(); // Call the scroll method when the step changes
      },
      scrollToTop() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
  
      // dots and slide-button
      goToSlide(slideIndex) {
        this.activeSlide = slideIndex;
      },
  
      nextSlide() {
      if (this.cardPick[this.step] == '9') {
        alert('가장 비슷한 그림을 선택해주세요 👉👈');
      } else {
        this.nextStep();
        this.activeSlide = this.step;
        }
      },
      
      priorSlide() {
        this.priorStep();
        this.activeSlide = this.step;
      },

      // bindingStyleRemove() {
      //   const cardContainers = document.querySelectorAll('.slide-card');
      //   cardContainers.forEach((card) => {
      //     card.classList.remove('selected');
      //   });
      // },
    }
  }
  </script>
  
  <style scoped>
  .loading{
    margin-top: 30%;
  }
  p{
    font-size: 1rem;
    margin:0;
  }
  .slide-container {
    display: flex;
    align-items: center; /* Center items vertically */
    position: relative;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .main-contents{
    margin-left: auto;
    margin-right: auto;
  }
  /* Dots */
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  .nav-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
  }
  .active-dot {
    background-color: var(--warm)
  }
  
  /* 기존 코드 */
  .slide-card.selected {
    box-shadow: var(--beige) 0px 0px 0px 3px;
  }
  .card-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .slide-card {
    border: none;
    border-radius: 5px;
    width: 7rem; height: 12rem;
    margin: 0.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    overflow: hidden;
    position: relative; /* .slide-card에 position: relative를 설정해야 하위 요소의 absolute 위치가 상대적으로 결정됨 */
  }
  .slide-card>img{
    width: 6rem;
    margin-right:auto;
    margin-left:auto;
    object-fit: cover;
    position: relative;
    position: absolute; /* 이미지를 absolute로 설정하여 위치를 조정 */
    bottom:0;
    left: 8%;
    transform: translateX(-50%); /* 이미지의 가로 크기의 절반만큼 왼쪽으로 이동하여 가로 중앙에 정렬 */
  }
  /* .slide-card:hover{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    background-color: rgb(255, 255, 152);
  } */
  .slide-card:active{
    box-shadow: var(--beige) 0px 0px 0px 3px;
  }
  
  /* 슬라이드 앞뒤버튼 */
  .slide-button{
    display: flex;
    position: absolute;
    bottom: 0;
    cursor: pointer;
  }
  .prev-slide{
    color: rgb(175, 175, 175);  
  }
  .next-slide{
    color: var(--warm);
    right: 0;
    margin-left: 10px; /* Add some spacing between the buttons */
  }
  
  </style>