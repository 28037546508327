export default [
  {
    id:0,
    'deco': '나도 모르는 나..',
    'main': '당신, 어떤 사람인가요?',
    'tab': 'basic',
    'title': '전체운세',  },
  {
    id:1,
    'text': '감정선의 모양과 길이는 어떤가요?',
    'help': '* 감정선 : 새끼손가락 밑에서 검지 쪽으로 향하는 선',
    'opts': ['직선 / 중지 정도', '직선 / 검지 이상', '약간 휜 곡선 / 검지 이전', '많이 휜 곡선 / 검지 이전', '곡선 / 검지 이상' ],
    'ans': [
      {'keyword':'자유로운 은둔자', 'more':'사회적인 인정보다는 개인적인 만족감을 중시하기에 남 앞에 잘 나서지 않는 경향이 있습니다. 사사로운 정에 휘둘리지 않고 신속하게 판단할 수 있는 차가운 머리를 가지고 있습니다. 냉정해 보일 수도 있지만 시원시원하고 솔직해서 오히려 함께하기 편할 수도 있는 성격입니다.'}, 
      {'keyword':'이성적인 직설가', 'more':'감정에 얽매이지 않고 합리적으로 판단하는 이성적인 사람입니다. 하지만 성급하게 말을 직설적으로 내뱉어서 사람들을 당황하게 만들기도 합니다. 두뇌 회전이 빠르며 회계 직무와 같이 숫자를 다루는 일에 잘 맞습니다.'},
      {'keyword':'차가운 머리, 뜨거운 가슴', 'more':'이성적인 동시에 인간미도 갖추고 있습니다. 실제로는 정이 많은 따뜻한 사람이나, 자신의 감정이나 생각을 잘 드러내지는 않습니다. 자기 생각을 다른 사람에게 강요하지 않고 다른 사람의 영역을 존중해줄 수 있는 사람입니다. '}, 
      {'keyword':'따뜻한 현실주의자', 'more':'이상보다는 현실을 중시하며, 흐름을 거스르지 않고 사람들과 조화롭게 어울립니다. 책임감이 강해 주변 사람들을 세심하게 챙기는 따뜻한 사람이기도 합니다.'}, 
      {'keyword':'그릇이 큰 리더', 'more':'프라이드가 높고 열정적이며, 사람들에게 따뜻하고 대인배적인 리더십을 발휘하는 그릇이 큰 지도자입니다. 인정이 많아 누구든지 자신보다 먼저 배려하고 부드럽게 대하려고 노력하는데, 이로 인해 종종 손해를 보기도 합니다.'},
    ]
  },
  {
    id:2,
    'text': '감정선의 상태는 어떤가요?',
    'help': '* 감정선 : 새끼손가락 밑에서 검지 쪽으로 향하는 선',
    'opts': ['연함','선명함','감정선이 두개로 보임'],
    'ans': [
      {'keyword':'착한 사람', 'more':'예민하고 감정 기복이 심한 편입니다. 인간관계에 민감해서 상대의 기분을 잘 알아채고 대인관계가 원활합니다. 하지만 정작 본인은 스트레스를 많이 받습니다.'},
      {'keyword':'따뜻한 사람', 'more':'뒤끝 없이 시원시원한 성격으로, 어떤 일이든지 빨리 털어버리는 건강한 정신의 소유자입니다. 열정적으로 사랑하고 적극적으로 삶을 살아가는 활기찬 사람입니다. 인연을 소중히 여기고 사람들을 관대하게 품으며 관계를 오래 이어갑니다.'},
      {'keyword':'외유내강형 예술가','more':'겉보기에는 온순해 보이지만, 열정이 넘치고 정신력이 대단히 강한 외유내강 유형입니다. 감수성이 남달라 예술의 세계에서 독창적인 세계를 창조하는 작가나 예술가, 또는 연기파 배우가 많습니다. 한편, 과한 감성 때문에 일반적인 인간관계나 직업 활동에 어려움을 겪을 수도 있습니다.'}
    ]
  },
  {
    id:3,
    'text' : '두뇌선이 어디까지 뻗어있나요?',
    'help' : '* 두뇌선 : 검지와 엄지 사이에서 손바닥을 가로지르는 선',
    'opts' : ['약지 이전', '약지 이상'],
    'ans': [
      {'keyword':'신속한 행동파', 'more':'직감으로 빠르게 판단하고 행동하는 사람으로 실용적이고 현실적인 주제에 관심이 많습니다. 실행 결과가 바로바로 나오는 일을 선호하기 때문에 직접 부딪치면서 기술을 익히는 일이 잘 맞습니다. 직감이 뛰어나고 두뇌 회전이 빨라 임기응변에 능합니다.'},
      {'keyword':'심사숙고 생각파', 'more':'공부 욕심이 많으며 깊이 생각하고 분석하는 것을 좋아합니다. 바로 결과가 나오지 않아도 오랫동안 집중할 수 있고, 머리로 먼저 이해가 되어야 행동에 옮길 수 있습니다. 생각이 많아 행동으로 옮기는 데 오래 걸릴 수 있는데 충분한 시간을 가지고 몰두할 수 있는 환경이 주어진다면 능력을 충분히 발휘할 수 있습니다.'},
    ]
  },
  {
    id:4,
    'text': '두뇌선이 어디서 시작하나요?',
    'help' : '* 두뇌선 : 검지와 엄지 사이에서 손바닥을 가로지르는 선 <br> * 생명선 : 검지와 엄지 사이에서 손목쪽으로 향하는 선',
    'opts': ['생명선과 동시에 시작','생명선에 붙어서 시작','생명선 아래쪽에서 시작','생명선과 떨어져 시작','손바닥에서 시작'],
    'ans': [
      {'keyword':'상식적인 사람', 'more':'사람들과 조화롭게 어울리며, 흐름을 거스르지 않고 상식적으로 사는 것을 중요시합니다. 어떤 일이든 치밀하게 준비한 후에 행동으로 옮기는 신중함을 가지고 있습니다. 생명선과 두뇌선의 출발점이 엄지와 검지 사이 중간보다 위쪽이면 주목받기를 선호하는 리더 유형에, 아래쪽이면 팔로워 유형에 가깝습니다.'},
      {'keyword':'조화로운 사람', 'more':'눈에 띄는 것을 좋아하지 않고 경계심이 많으나, 다른 사람에게 관심이 많아 사람들과 잘 어울리는 편입니다. 대단히 신중해서 중요한 결정을 내리거나 정확한 판단력이 필요한 상황에서 강점을 발휘할 수 있습니다. '},
      {'keyword':'할 때는 한다', 'more':'평소에는 조심성이 많고 사소한 일에도 마음을 졸이지만, 자신감이 생기면 상식을 벗어난 행동도 대담하게 해내는 강심장을 발휘합니다. 민감한 감수성을 살릴 수 있는 취미나 일을 하면 강점을 살릴 수 있습니다.'},
      {'keyword':'대담한 자유인', 'more':'생각을 즉시 실행에 옮기는 행동파이며, 프라이드가 높고 다른 사람의 의견을 듣는 것을 좋아하지 않아 무엇이든 스스로 결정하려고 합니다. 관습이나 정형화된 조직구조에 대한 반발심이 강해 조직 생활을 오래 버티지 못하고 자유로운 환경을 찾아 창업을 선택하는 경우가 많습니다. 프리랜서로 일하면 크게 활약할 수 있으며, 전업주부로 집 안에만 있으면 답답함을 느끼게 됩니다. 생명선과 두뇌선의 간격이 넓을수록 간섭이나 속박당하는 것을 싫어하는 성향이 강해집니다.'},
      {'keyword':'고생해도 괜찮아', 'more':'감수성이 아주 섬세하고 사소한 고민이 많으며 관심 분야가 자주 변합니다. 충동적으로 행동하는 경향이 있어 젊어서 고생을 많이 하지만, 직접 부딪히면서 배워서 자기만의 세계를 만들어가는 특별한 사람입니다. 독립적으로 일하는 것이 조직 생활보다 어울립니다.'} 
    ]
  },
  {
    id:5,
    'text': '두뇌선이 어떤 모양인가요?',
    'help' : '* 두뇌선 : 검지와 엄지 사이에서 손바닥을 가로지르는 선',
    'opts': ['직선에 가까움','휘어진 곡선','2~3갈래로 갈라짐','두뇌선이 두 개로 보임'],
    'ans': [
      {'keyword':'명석한 사업가', 'more':'비즈니스 감각, 금전 감각이 뛰어납니다. 사교적이고 시원시원한 성격이며, 항상 재미있는 이야깃거리가 많아서 함께 있으면 편하고 즐거운 사람입니다. 두뇌 회전이 빠르고 논리적이며, 현실적이고 온정에 흔들리지 않는 냉철한 판단 능력을 갖추고 있어 사업, 경영, 투자에 재능이 있습니다.'},
      {'keyword':'창의적인 예술가', 'more':'상상력과 창의력이 풍부합니다. 두뇌선이 살짝 휘어졌으면 현실적이고 사교성이 뛰어나서 사람들과 어울리며 생활하는 사무직이 잘 맞습니다. 만약 두뇌선이 깊게 휘어졌으면 돈이 되지 않아도 하고자 하는 일을 하는 사람입니다. 자신이 하는 일에 장인정신을 발휘하기 때문에 자신의 방식대로 일할 수 있는 독립적인 환경이 필요합니다. 또한 두뇌선은 늦게 잘 수록 길이가 점점 더 길어지는 경향이 있습니다.'},
      {'keyword':'다재다능 팔방미인', 'more':'호기심이 많고 비즈니스 스킬과 창의성을 모두 갖춘 팔방미인입니다. 융통성 있고 상황 대처 능력이 뛰어나기 때문에 다양한 사람을 만나는 일에서 두각을 드러낼 수 있습니다.'},
      {'keyword':'멀티에 능한 천재', 'more':'겉보기에 왠지 특이해 보이며 독창적인 아이디어가 넘치고 두뇌 능력이 탁월합니다. 컴퓨터에 비유하면 일반인보다 코어 개수가 두 배인 셈이라 한 번에 여러 가지 일을 수행하는 능력이 뛰어납니다. 어릴 때 급격한 문화권 변화, 예를 들어 외국으로 이민을 가는 경우에 이중두뇌선이 형성되기도 합니다. 일을 잘하는 사람들에게 많이 나타나는 손금으로, 창업으로 성공을 거두는 경우도 많습니다.'},
    
    ],
  },
  {
    id:6,
    'text': '생명선의 상태와 모양은 어떤가요?',
    'help' : '* 생명선 : 검지와 엄지 사이에서 손목쪽으로 향하는 선',
    'opts': ['선명함 / 휘어진 곡선', '연함 / 직선에 가까움', '생명선이 두 개로 보임'],
    'ans': [
      {'keyword':'몸이 근질근질', 'more':'건강한 체질로 잔병치레가 많지 않고, 운동과 신체 활동을 좋아합니다. 자기 어필에 능숙하고 인생을 정력적으로 살아갑니다. 사회적인 활동을 찾아서 하며, 주도적으로 일을 추진하는 활동적인 사람입니다.'},
      {'keyword':'움직이기 싫어', 'more':'허약한 체질로 체력과 기력이 약해 육체적인 활동을 좋아하지 않습니다. 스태미나가 부족하고 체질적으로 민감해서 쉽게 지칩니다. 내성적이고 예민한 성격으로 변화가 많은 환경에서는 스트레스를 많이 받기 때문에 단순 반복되는 일상을 선호합니다.'},
      {'keyword':'활기찬 운동 매니아', 'more':'생명 에너지가 보통 사람의 두 배로 강합니다. 항상 에너지가 넘치고 삶을 주도적으로 살아가며 새로운 도전을 즐깁니다. 스태미나가 흘러넘쳐 가만히 있지 못하는 건강하고 활력 넘치는 사람으로 감정 표현도 굉장히 풍부합니다.'},
    ]
  },
]