import { createApp } from 'vue'
import App from './App.vue'
import store from './store'

createApp(App).use(store).mount('#app')


// BookPreview에 지연로딩 해보려고 했는데 안됨
// import { createApp } from 'vue';
// import App from './App.vue';
// import store from './store';

// const app = createApp(App);

// app.directive('lazy', {
//   mounted(el, binding) {
//     const options = {
//       root: null,
//       rootMargin: '0px',
//       threshold: 0.1,
//     };

//     const observer = new IntersectionObserver((entries) => {
//       const entry = entries[0];
//       if (entry.isIntersecting) {
//         el.src = binding.value;
//         observer.unobserve(el);
//       }
//     }, options);

//     observer.observe(el);
//   },
// });

// app.use(store).mount('#app');
