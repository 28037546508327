<template>
  <div class="view-bg">

    <!-- 6초 전 -->
    <div class="loading-container" v-if="showLoading">
      <h4>결과 생성중입니다</h4>
      <div class="loading"></div>
      <div class="mt-3">
        <!-- 쿠팡 정사각 320x320 -->
        <iframe src="https://ads-partners.coupang.com/widgets.html?id=696077&template=carousel&trackingCode=AF5629648&subId=&width=320&height=320&tsource=" width="320" height="320" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
      </div>
      <!-- <img src="@/assets/waiting.png" class="loading-img" alt="손금"> -->
    </div>


    <!-- 6초 후 결과 -->
    <div class="result-container-top mt-4" v-if="showResult">

      <!-- <div class="coupang-container">
        <iframe src="https://ads-partners.coupang.com/widgets.html?id=696031&template=banner&trackingCode=AF5629648&subId=&width=728&height=90" width="728" height="90" frameborder="0" scrolling="no" referrerpolicy="unsafe-url"></iframe>
      </div> -->

      <p class="mb-4 gothic" style="font-weight:bold;">🔔 파란 글씨를 눌러보세요</p>
      <div class="result-container" ref="resultContainer">
        <span class="deco-text">{{endData[0].title}} 분석결과</span>
        <div class="result-cards mt-3">
          <h4 @click="show1=!show1">{{endData[1].ans[cardPick[1]].keyword}}</h4>
          <p v-if="show1">{{endData[1].ans[cardPick[1]].more}}</p>
          <hr>

          <h4 @click="show2=!show2">{{endData[2].ans[cardPick[2]].keyword}}</h4>
          <p v-if="show2">{{endData[2].ans[cardPick[2]].more}}</p>
          <hr>

          <h4 @click="show3=!show3">{{endData[3].ans[cardPick[3]].keyword}}</h4>
          <p v-if="show3">{{endData[3].ans[cardPick[3]].more}}</p>
          <hr>

          <h4 @click="show4=!show4">{{endData[4].ans[cardPick[4]].keyword}}</h4>
          <p v-if="show4">{{endData[4].ans[cardPick[4]].more}}</p>
          <hr>

          <h4 @click="show5=!show5">{{endData[5].ans[cardPick[5]].keyword}}</h4>
          <p v-if="show5">{{endData[5].ans[cardPick[5]].more}}</p>
          <hr>

          <h4 @click="show6=!show6">{{endData[6].ans[cardPick[6]].keyword}}</h4>
          <p v-if="show6">{{endData[6].ans[cardPick[6]].more}}</p>

          <div class="flex-center mt-4">
            <img src="@/assets/logo.png" class="logo-img" alt="손금">
            <span class="title">쇼미더손금</span>
          </div>
        </div> 
      </div>

      <div class="mt-4 mb-3 share-container" >
        <div class="capture-screenshot" @click="captureScreenshot">
          <span class="share-item">📷 결과저장</span>
        </div>
        <div @click="resultShare">
          <img src="@/assets/kakao.png" class="kakao-icon" alt="카카오톡">
          <span class="share-item">카톡공유</span>
        </div>
        <div @click="toHome">
          <span class="share-item">🎁 다시하기</span>
        </div>
      </div>

      <p class="help-text gothic">※ 결과 저장 시 보이는 대로 저장됩니다</p>

    </div>
    
  </div>



</template>

<script>
import html2canvas from 'html2canvas';
import {mapState, mapMutations} from 'vuex';
export default {
  data(){
    return {
      showResult: false,
      showLoading: true,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,

    }
  },
  computed: {
    ...mapState(['step','showTab','data','cardPick','cardPick']),
    endData() {
      return this.data[this.showTab];
    },
  },
  methods: {
    ...mapMutations(['toHome']),


    // 스샷 저장
    async captureScreenshot() {
      try {
        const resultContainer = this.$refs.resultContainer;

        // 화질 개선을 위해 스크린샷 크기를 기존의 2배로 설정
        const scale = 2;

        // html2canvas를 사용하여 스크린샷 캡처
        const canvas = await html2canvas(resultContainer, {
          useCORS: true, // CORS 이슈를 방지하기 위해 사용
          scale: scale, // 스크린샷의 해상도 조정
        });

        // 캔버스 데이터를 이미지 URL로 변환
        const imageDataURL = canvas.toDataURL('image/png');

        // 이미지 다운로드를 위한 가상 링크 생성
        const link = document.createElement('a');
        link.href = imageDataURL;
        link.download = `${this.endData[0].title} 결과.png`;

        // 가상 링크 클릭하여 이미지 다운로드 실행
        link.click();
      } catch (error) {
        console.error('스크린샷 캡처 오류:', error);
      }
    },

    // 카톡 결과 공유 (txt)
    resultShare () {
      window.Kakao.Share.sendDefault({
        objectType: 'text',
        text: 
        `
          ===== 손금으로 본 "${this.endData[0].title}" 결과 =====
          🔴 ${this.endData[1].ans[this.cardPick[1]].keyword}
          🟠 ${this.endData[2].ans[this.cardPick[2]].keyword}
          🟡 ${this.endData[3].ans[this.cardPick[3]].keyword}
          🟢 ${this.endData[4].ans[this.cardPick[4]].keyword}
          🔵 ${this.endData[5].ans[this.cardPick[5]].keyword}
          🟣 ${this.endData[6].ans[this.cardPick[6]].keyword} 
        `,
          
        link: {
        mobileWebUrl: 'https://songeum.net/',
        webUrl: 'https://songeum.net/',
        }
      })
    }
  },
  mounted(){
    setTimeout(()=>{
      this.showResult = true;
      this.showLoading = false;
      }, 6000);
  }
}

  





</script>

<style scoped>
p{
  word-break:break-all;
  text-align: start;
}
h4{
  cursor: pointer;
}
.view-bg{
  padding-top:0;
}

</style>