<template>

<div class="home-container pt-4">
  <div class="mb-5 mt-2 home-text">
    <p class="button-text">{{ buttonText }}</p>
    <span class="big-text">{{ bigText }}</span>
    <p class="mt-4 gothic">검사시간 : 3분</p>
  </div>

  <div class="card-container mb-5">
    <div class="card col-sm-4 card1" @mouseover="updateButtonText(0)" @mouseout="resetButtonText" @click="toBasic">
      <div class="icon-img">💎</div>
      <div class="text">전체운세</div>
    </div>
    <div class="card col-sm-4 card2" @mouseover="updateButtonText(1)" @mouseout="resetButtonText" @click="toLove">
      <div class="icon-img">💖</div>
      <div class="text">연애운세</div>
    </div>
    <div class="card col-sm-4 card3" @mouseover="updateButtonText(2)" @mouseout="resetButtonText" @click="toRich">
      <div class="icon-img">💰</div>
      <div class="text">금전운세</div>
    </div>
  </div>
  


  <div class="mt-5" @click="kakaoLink">
    <img src="@/assets/kakao.png" class="kakao-icon" alt="카카오톡">
    <span class="gothic share-item">카카오톡 공유하기</span>
  </div>


<!-- <hr style="width: 90%; margin-left:auto; margin-right:auto; margin-top:2rem;"> -->

  <div class="narrow-ad">
    <!-- 중간 띠 광고 -->
    <!-- <ins class="kakao_ad_area" style="display:none;"
    data-ad-unit = "DAN-N9exZEaVRfvh087k"
    data-ad-width = "320"
    data-ad-height = "50"></ins> -->
  </div>



  <div class="mak-container">
    <p class="gothic" style="font-weight: bold;">※ 막쥔손금</p>
    <p>전체인구의 1.5% 정도는 손이 이렇게 생겼어요</p>
    <p>해당되신다면 여기를 눌러주세요</p>
    <div class="card-container mt-2">
      <div class="card row col-sm-12 col-lg-4 card4" @click="toMak"><div class="text"></div></div>
    </div>
  </div>

</div>


</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  data(){
    return{
      bigText: '무료 손금 테스트',
      buttonText: '손금은 과학',
      change:[['전체운세','나도 모르는 나...'],['연애운세','나의 반쪽은 어디에?'],['금전운세','머니머니해도 머니!']],
      showMak: false,
    }
  },
  methods: {
    ...mapMutations(['nextStep','toBasic','toLove','toRich','toMak']),
    updateButtonText(i) {
      this.bigText = this.change[i][0];
      this.buttonText = this.change[i][1];
    },
    resetButtonText() {
      this.bigText = '무료 손금 테스트';
      this.buttonText = '손금은 과학';
    },
    kakaoLink () {
      window.Kakao.Link.sendCustom({
      templateId: 96398,
      })
    }
  },
  computed: {
    ...mapState(['step']),
  }
};
</script>


<style scoped>

.home-text{
  height: 9rem;
}
.mak-container{
  display:flex;
  flex-direction: column;
}
.big-text{
  color:rgb(50, 50, 50);
  font-weight: 700;
  font-size: 2.3rem;
  margin-bottom: 1rem;
}
.view-bg{
  padding-top: 10vh;
}
.button-text{
  font-size: 2rem;
  font-family: 'Nanum Pen Script', cursive;
  color: var(--beige);
}
.card-container{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.card{
  border: none;
  height: 150px; width: 100px;
  cursor: pointer;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.card:hover{
  box-shadow: var(--beige) 0px 0px 0px 3px;
}
.card4{
  background-image: url("@/assets/mak-hands/1/1.png");
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center center;
}
.icon-img{
  position: absolute;
  font-size: 4rem;
  top:35%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.text{
  position: absolute;
  font-size: 1rem;
  top:80%;
  left: 50%;
  transform: translate(-50%, -50%);
}

</style>