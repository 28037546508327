<template>
  <div class="black-bg" >
    <div class="white-bg row">
      <div class="modal-header">
        <span class="close-btn" @click="modalHandler">X</span>
      </div>
      <div class="modal-contents">
        <h2 v-if="imgNum!=0" class="mb-4">{{ explain[imgNum][0] }}</h2>
        <div v-if="imgNum === 0">
          <h2>궁금한 선 이름을</h2>
          <h2>클릭해보세요</h2>
        </div>
        <div class="explain">
          <p>{{explain[imgNum][1]}}</p>
        </div>
        <img :src="require(`@/assets/modal-img/${imgNum}.png`)" 
          class="modal-img col-lg-9"
          alt="감정선 두뇌선 생명선 운명선"
          />
        <div class="name-container">
          <div class="name" >
            <span @click="imgNum=1">● 감정선</span>
            <span @click="imgNum=2">● 두뇌선</span>
            <span @click="imgNum=3">● 생명선</span>
            <span @click="imgNum=4">● 운명선</span>
          </div>
          <div class="name" >
            <span @click="imgNum=5">● 결혼선</span>
            <span @click="imgNum=6">● 금성대</span>
            <span @click="imgNum=7">● 사업선</span>
            <span @click="imgNum=8">● 재물선</span>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex'
export default {
  data(){
    return {
      imgNum:0,
      explain: [
        ['',''],
        ['감정선', '새끼손가락 밑에서 검지 쪽으로 향해요'],
        ['두뇌선', '검지와 엄지 사이에서 손바닥을 가로질러요'], 
        ['생명선', '검지와 엄지 사이에서 손목쪽으로 향해요'],
        ['운명선', '출발점에 상관없이 중지쪽으로 향해요'],
        ['결혼선', '새끼손가락 끝부분에서 가로로 뻗어나와요'],
        ['금성대', '활모양으로 중지와 약지를 감싸요'],
        ['사업선', '새끼 손가락 밑에 있는 세로선이에요'],
        ['재물선', '약지 밑에 있는 세로선이에요.'],
      ]
    }
  },
  computed:{
    ...mapState(['openModal'])
  },
  methods: {
    ...mapMutations(['modalHandler'])
  }
}
</script>

<style scoped>


.modal-img{
  width:200px;
  margin-left:auto;
  margin-right:auto;
}
.name-container{
  display: flex;
  flex-direction: column;
  margin-left:auto;
  margin-right:auto;
  margin-bottom: 30px;
}
.name{
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 10px;
}
.name>span{
  cursor:pointer;
  color:var(--beige);
  font-size: 0.9rem;
}
.name>span:hover{
  font-weight: bold;
  color:var(--milkyGreen)
}
.close-btn{
  right: 2rem;
  color: var(--beige);
}

</style>