export default [
  {
    id:0,
    'deco': '나의 반쪽은 어디에..',
    'main': '사랑, 잘 하고 계신가요?',
    'tab': 'love',
    'title': '연애운세',
  },
  {
    id:1,
    'text': '감정선의 모양과 길이는 어떤가요?',
    'help': '* 감정선 : 새끼손가락 밑에서 검지 쪽으로 향하는 선',
    'opts': ['직선 / 중지 정도', '직선 / 검지 이상', '곡선 / 검지 이전', '곡선 / 검지 이상' ],
    'ans': [
      {'keyword':'내 맘대로 할거야', 'more':'상대와 깊은 정신적 유대관계를 갖기 보다는 가벼운 만남을 선호하고 육체적 사랑에 탐닉합니다.  감정적인 관계를 피곤해하며, 다른 사람의 시선을 신경 쓰지 않고 자기 마음 흐르는 대로 하고 싶은 것을 다 하며 지내는 유형입니다.'},
      {'keyword':'친구에서 연인으로', 'more':'화려한 외모보다는 말이 잘 통하는 사람을 선호합니다. 천천히 사랑을 키워가는 편이며, 친했던 사람 중에서 연인관계로 발전되는 경우가 많습니다.'},
      {'keyword':'연애=결혼', 'more':'관계에 진지하며 “연애=결혼”이라 생각하는 보수적인 결혼관의 소유자입니다. 이성을 사귐에 신중하며 상대를 끝까지 책임지는 멋있는 사람입니다.'},
      {'keyword':'정열적인 로맨티스트', 'more':'배우자를 고르는 안목이 높아 조건을 많이 따지지만 한번 마음을 연 상대에게는 걷잡을 수 없이 빠져드는 경향이 있습니다. 사랑에 빠지면 로미오와 줄리엣처럼 사랑에 눈이 멀어 모든 현실적인 문제는 안중에서 사라지는 로맨티스트입니다.'}
    ]
  },
  {
    id:2,
    'text': '감정선의 끝에 갈라진 부분을 보아요',
    'help': '* 감정선 : 새끼손가락 밑에서 검지 쪽으로 향하는 선',
    'opts': ['세갈래 / 검지 이전', '세갈래 / 검지 이상', '두 갈래','해당없음'],
    'ans': [
      {'keyword':'알고보면 따뜻한 사람', 'more':'주변 사람을 생각하는 마음이 깊으며 배려심이 많으나, 생각을 말이나 행동으로 표현하는 능력이 부족해서 관계에 피로를 느끼고 있을 수 있습니다. 살짝 어설퍼 보일 수 있지만, 겉으로 보이는 것보다 속 깊고 따뜻한 사람입니다.'},
      {'keyword':'모두의 친구', 'more':'누구에게나 친절해서 새로운 사람과 쉽게 친구가 될 수 있습니다. 이해심이 많고 자신보다 다른 사람을 우선시하며 책임감을 갖고 보살피는 유형입니다. 모두에게 잘해주려고 하다가 애인에게 소홀해지는 우를 범하기도 합니다.'},
      {'keyword':'대쪽 같은 사람', 'more':'연인 관계에 충실하지만, 상대의 외도나 배신을 절대로 용납하지 않습니다. 눈 앞의 유혹에 잘 현혹되지 않고, 일단 결혼하고 나면 평생 신의를 지키며 사는 충성스러운 사람입니다. 하지만 그와 동시에 상대가 신뢰를 깨면 뒤도 안 돌아보고 헤어지는 칼 같은 사람입니다.'},
      {'keyword':'', 'more':''}
    ]
  },
  {
    id:3,
    'text': '결혼선이 몇 개인가요?',
    'help' : '* 결혼선 : 새끼손가락 아래 뻗은 가로선',
    'opts': ['선명함 / 1~2개', '선명함 / 3개이상', '연함 / 많음', '잘 안보이거나 없음'],
    'ans': [
      {'keyword':'최고의 배우자감', 'more':'“연애=결혼”이라고 여기고 결혼하면 상대만 바라보고 가정에 충실한 최고의 배우자감입니다. 배려심이 많고 따뜻한 성격으로, 신뢰를 바탕으로 만남을 안정적으로 이어갑니다.'},
      {'keyword':'두 사랑', 'more':'결혼을 생각할 만큼 깊은 인연이 두 명 이상 있거나, 재혼의 가능성도 있습니다. 또는 한 사람과 헤어졌다가 다시 만날 수도 있습니다. 어떤 경우이든 살아가면서 인생에 큰 영향을 끼치는 인연이 두 번 이상 있을 것입니다.'},
      {'keyword':'친절한 바람둥이', 'more':'섬세하고 다정다감한 성격으로, 항상 주변에 이성 친구가 많습니다. 이성의 마음을 쉽게 사로잡고 본인도 쉽게 반하는 바람둥이입니다.'},
      {'keyword':'결혼을 왜 하죠', 'more':'결혼에 대한 의욕이나 동경이 없는 상태입니다. 결혼선이 없다고 결혼할 수 없는 것은 아니며, 단지 지금으로서는 결혼에 관한 생각이 없는 상태를 의미합니다. 이미 결혼해서 안정적으로 행복한 결혼 생활을 하고 있을 때에도 결혼선이 연해질 수 있습니다.'}
    ]
  },
  {
    id:4,
    'text': '가장 선명한 결혼선의 방향을 보아요',
    'help' : '* 결혼선 : 새끼손가락 아래 뻗은 가로선',
    'opts': ['끝부분이 내려감', '끝부분이 올라감','해당없음'],
    'ans': [
      {'keyword':'혼자만의 시간', 'more':'오래 연애하거나 결혼하면 결혼선이 살짝 내려가는 것은 자주 있는 일입니다. 뜨거웠던 사랑의 열기가 시들해진 상태로 연인 관계보다는 일이나 다른 관심사에 집중하고 있는 상태입니다. 이때 두 사람 모두 결혼선이 내려갔다면 각자의 시간을 보내면서 안정적으로 관계를 지속할 수 있지만, 한 명만 내려갔다면 불만이 쌓이고 있을 수 있습니다. 만일 솔로에게 이런 손금이 나타난다면 딱히 이성을 만나서 연애하고 싶은 생각이 없다는 뜻입니다.'},
      {'keyword':'최고의 결혼', 'more':'결혼선이 살짝 올라가면 배려심 많고 긍정적인 성격으로 멋진 결혼생활에 완벽하게 준비된 사람입니다. 아직 애인이 없다면 머릿속으로 상상했던 것보다 더 좋은 사람을 만나게 될 것입니다. 결혼 상대가 있는 경우에는 지금 결혼을 하면 자신의 능력을 펼칠 기회가 생기며 운수가 확 트입니다. 지금이 결혼운이 최고로 좋은 황금기입니다.'},
      {'keyword':'', 'more':''}
    ]
  },
  {
    id:5,
    'text': '결혼선 중 가장 선명한 선의 위치가 어딘가요?',
    'help' : '* 결혼선 : 새끼손가락 아래 뻗은 가로선',
    'opts': ['낮음', '중간', '높음', '해당없음'],
    'ans': [
      {'keyword':'과속 스캔들', 'more':'일반적인 결혼 연령보다 일찍 결혼합니다. (단, 여기서 "결혼"이라 함은 "결혼을 생각할만큼 깊은 인연"으로 대체해서 이해해도 됩니다.)'},
      {'keyword':'적령기에 결혼', 'more':'일반적인 결혼 연령에 결혼합니다. (단, 여기서 "결혼"이라 함은 "결혼을 생각할만큼 깊은 인연"으로 대체해서 이해해도 됩니다.)'},
      {'keyword':'늦은 결혼', 'more':'일반적인 결혼 연령보다 늦게 결혼하게 됩니다. (단, 여기서 "결혼"이라 함은 "결혼을 생각할만큼 깊은 인연"으로 대체해서 이해해도 됩니다.)'},
      {'keyword':'', 'more':''}
    ]
  },
  {
    id:6,
    'text': '금성대의 모양이 어떤가요?',
    'help': '* 금성대 : 중지와 약지 하단을 감싸는 활모양의 선',
    'opts': ['깔끔한 활 모양', '가운데가 끊어짐', '토막 토막 끊어짐','여러 줄로 이루어짐', '해당없음'],
    'ans': [
      {'keyword':'아름다운 인기쟁이', 'more':'감수성과 미적 감각이 뛰어나며, 평범하지 않은 화려하고 열정적인 연애를 합니다. 이성에 대해 어릴 때부터 관심이 많고, 성을 일찍 깨우치는 경향이 있습니다. 생각하는 것을 좋아해서 가만히 있을 때도 무언가 골똘히 생각하고 있습니다. 자기만의 독특한 세계관이 있는 뇌섹남, 뇌섹녀이며 이성에게 인기가 많습니다.'},
      {'keyword':'매니아층 보유', 'more':'대중적으로 인기 있는 스타일은 아니지만, 소수의 매니아층을 홀딱 반하게 하는 개성 있는 매력의 소유자입니다. 감성이 풍부하고 온화한 심성을 가지고 있어 사람들이 많이 호감을 느낍니다.'},
      {'keyword':'섬세한 변태', 'more':'금성대가 지저분하게 토막토막 끊어져 있는 경우, 자극적인 것을 좋아하고 성적 취향이 독특한 경향이 있어 “변태 손금”이라고도 합니다. 그러나 감수성이 풍부하고 분위기 메이커이며 자신을 세련되게 잘 꾸며서 인기가 많습니다. 한편, 싫증을 잘 내서 연애가 오래가지 못하고 헤어져도 곧바로 다른 애인이 생기거나 여러 사람을 동시에 만나기도 하는 바람둥이입니다. '},
      {'keyword':'카사노바', 'more':'미적 감각이 뛰어나고 감정이 풍부하며, 카사노바와 같은 마성의 매력의 소유자입니다. 가만히 있어도 유혹적인 분위기를 풍기며, 별 노력 없이도 말이나 행동으로 상대를 홀릴 수 있는 능력이 있습니다. 본인도 이성에게 성적으로 관심이 많으며, 연인관계가 깔끔하지 못한 경향이 있습니다.'},
      {'keyword':'', 'more':''}
    ]
  },
]