export default [
  {
    id:0,
    'deco': '머니머니해도 머니',
    'main': '내가 부자가 될 상인가?',
    'tab': 'rich',
    'title': '금전운세',
  },
  {
    id:1,
    'text': '운명선의 상태는 어떤가요?',
    'help': '* 운명선 : 출발점에 상관없이 중지 쪽으로 향하는 선',
    'opts': ['선명함','없거나 연함' ],
    'ans': [
      {'keyword':'주인공은 나야나!', 'more':'강인하고 활발한 성격으로, 누구에게도 의존하지 않고 자기만의 방식으로 인생길을 만들어가는 개척자입니다. 워커홀릭 기질이 강해 한번 일을 손에 잡으면 걷잡을 수 없이 몰입하게 되어 다른 사적인 인간관계들은 잊어버리기도 합니다. 주인공 의식이 강하기 때문에 다른 사람에게 의견을 물어보기보다는 모든 결정을 스스로 내리고 혼자 많은 일을 짊어지고는 합니다.'},
      {'keyword':'조화로운 자유인', 'more':'뚜렷한 인생 목표가 없거나, 사회적인 통념에 얽매이지 않고 자유롭게 살아가는 경향이 있습니다. 직업이나 관계에 구속 당하는 것을 싫어하지만 자기주장을 강하게 내세우는 일 없이 어떤 유형의 사람과도 조화롭게 어울릴 수 있는 능력이 있습니다. 돈, 명예, 권력 같은 세속적인 욕망보다는 자유로운 삶과 정신적인 만족을 우선시하며, 책임감이 부족한 편입니다.'},
    ]
  },
  {
    id:2,
    'text': '운명선이 어디서 시작하나요?',
    'help': '* 운명선 : 출발점에 상관없이 중지 쪽으로 향하는 선',
    'opts': ['손목 위 정중앙', '생명선 안쪽', '생명선 안쪽 위','생명선 위', '손날쪽 손바닥 하단','해당없음'],
    'ans': [
      {'keyword':'야무진 모범생', 'more':'독립심이 강해 무엇이든 스스로 결정하고 노력하는 강인한 정신력의 소유자입니다. 직업운을 타고나서 노력에 따라 대성할 수 있는 손금으로, 모범적인 학창 시절을 보내고 대기업, 관공서와 같은 곳에 몸을 담는 경우가 많습니다. 부모님 곁을 떠나 생활하는 경우가 많으며, 가족이나 친척의 생계를 책임지기도 합니다.'},
      {'keyword':'가족의 은혜', 'more':'가족, 고향을 의미하는 금성구에서 운명선이 시작하면 집안의 도움을 받으면서 운이 트입니다. 가족 사업을 이어받거나, 재산을 상속받거나, 좋은 짝을 소개받는 등 가족이나 친지에게 큰 은혜를 입을 수 있습니다.'},
      {'keyword':'사랑받는 애제자', 'more':'경쟁심이 강하며 야무진 성격으로 붙임성이 좋아 윗사람에게 총애를 받는 유형입니다. 어디를 가든 사랑받으며, 만나면 왠지 도움을 주고 싶은 마음이 들게 되는 복 받은 손금입니다. 배우나 음악인 등과 같은 불안정한 직업군에서 많이 나타납니다.'},
      {'keyword':'자수성가 노력가', 'more':'다른 사람의 도움 없이 혼자 치열하게 노력해서 성공을 이루는 자수성가 유형입니다. 성공의 방식은 의사, 변호사, 회계사처럼 취득하기 굉장히 어렵고 오래 걸리는 전문자격증 공부를 통해 목표를 달성하는 경우가 많습니다. 또는 혼자 힘으로 사업을 번성시키기도 합니다.'},
      {'keyword':'타인의 도움', 'more':'창의적이고 자유분방한 성격으로, 구속이 없는 자유로운 환경에 있게 되면 물 만난 물고기처럼 재능을 꽃피우고 활약할 수 있습니다. 집안사람들보다는 타인과의 연이 깊으므로, 고향을 벗어나 활동 반경을 넓히면 운이 확 트입니다. 새로운 곳에 쉽게 적응할 수 있고 사람들이 도와줘서 일이 잘 풀리게 됩니다.'},
      {'keyword':'', 'more':''},
    ]
  },
  {
    id:3,
    'text': '사업선이 어디서 시작하나요?',
    'help': '* 사업선 : 새끼 손가락 밑에 있는 세로선',
    'opts': ['손바닥', '두뇌선 위', '운명선 위', '생명선 위', '생명선 안쪽', '손날쪽 손바닥 하단', '없거나 연함'],
    'ans': [
      {'keyword':'고진감래', 'more':'많은 경험을 하며 파란만장한 청년기를 보내는데 그 과정에서 고생도 많이 하게 됩니다. 40-50대 정도로 나이가 들면 청년기에 경험으로 얻은 노하우를 기반으로 사업이 잘 풀리거나 새로운 직업을 가짐으로써 재운이 좋아집니다.'},
      {'keyword':'돈 버는 머리', 'more':'자신만의 특별한 아이디어로 사업에 도전하고 큰 성과를 냅니다. 비즈니스적인 안목이 뛰어나 어떤 일을 하든 차별화된 컨셉으로 사업을 성공시킬 수 있습니다. 그 뿐만 아니라 투자와 자산운용에도 재능이 있어 부동산이나 주식 투자로도 상당한 재산을 모으기도 합니다.'},
      {'keyword':'직업적 성공', 'more':'직업운이 굉장히 좋아 직장에서 능력으로 인정받고 승진하거나 성과금을 많이 받게 됩니다. 부모님의 도움이나 운에 의한 재물 획득이 아닌 자신의 노력을 통해 직업에서 성취를 거두고 재물을 얻어 부를 이룹니다.'},
      {'keyword':'자수성가 노력가', 'more':'다른 사람의 도움 없이 치열한 노력으로 사업이나 직업에서 성공을 거두고 부와 명예를 이루는 자수성가형 손금입니다. 의사, 변호사, 또는 회계사와 같은 전문 자격증에 기반한 성공을 하는 경우가 많습니다. 부동산 투자에도 일가견이 있어 돈을 크게 불릴 수 있습니다.'},
      {'keyword':'가업의 후계자', 'more':'가업을 이어가거나 부모님의 사업을 물려받게 됩니다. 장남, 장녀인 경우가 많으며 부모님의 사랑을 많이 받고 부모님의 덕을 많이 봅니다.'},
      {'keyword':'창의적인 크리에이터', 'more':'남다른 창의력과 예술적 감각을 기반으로 사업에 개성을 발휘하여 성공합니다. 독창적인 아이디어가 풍부하며, 영화감독, 작곡가, 유튜버, 이색카페 사장 등 창조적인 직업에 종사하는 사람에게서 많이 나타나는 손금입니다.'},
      {'keyword':'자유로운 영혼', 'more':'물욕이 많지 않고 금전 감각이 부족하며, 삶을 적당히 즐기고 즉흥적으로 소비하는 유형입니다. 비즈니스 정신과 인내력이 부족해 쉽게 싫증 내고 직장이나 업종을 자주 바꾸는 경향이 있습니다. 장사나 사업과는 인연이 적은 손금입니다.'}
    ]
  },
  {
    id:4,
    'text': '재물선의 상태가 어떤가요?',
    'help' : '* 재물선 : 약지 밑에 있는 세로선',
    'opts': ['선명함','없거나 연함'],
    'ans': [
      {'keyword':'큰 부자가 될 상', 'more':'성격이 밝고 사교적이며 매력적이고 인기가 많습니다. 직장이나 주변 사람들로부터 인정받아 일을 하는 사람은 탄탄대로를 걸어가고, 전업 주부도 풍족하고 안정된 생활을 누릴 수 있습니다. 넓은 인맥과 탁월한 사업수완을 기반으로 큰 재물을 모을 수 있어 큰 부자가 될 손금입니다.'},
      {'keyword':'돈이 안모여요', 'more':'일을 열심히 해도 돈을 잘 벌지 못하고 직장이나 사람들로부터 인정도 받지 못하고 있는 상태입니다. 하지만 재물선은 손금들 중에서도 가장 잘 바뀌는 손금이므로 걱정하지 않아도 됩니다. 현재 상황에 비관하지 않고 목표를 분명히 하고 재산을 모으기 위해 노력하면 재물운이 좋아지고, 좋아진 재물운이 손금에 반영되어 없던 재물선이 나타나거나 연하던 재물선이 선명해질 수 있습니다.'}
    ]
  },
  {
    id:5,
    'text': '재물선이 몇 개 있나요?' ,
    'help' : '* 재물선 : 약지 밑에 있는 세로선',
    'opts': ['선명함 / 1개', '선명함 / 2~3개', '연한 선이 많음', '없음'],
    'ans': [
      {'keyword':'하나만 판다', 'more':'주요 수입원이 하나뿐입니다. 하지만 그 하나의 재물선이 선명하다면 현명한 선택과 집중을 통해 모든 힘을 한 일에 집중해서 큰돈을 벌 수 있습니다. 재물선이 길고 굵게 뻗어 있을수록 경제적으로 풍요로운 생활을 누릴 수 있습니다. 하지만 만약 하나뿐인 재물선이 연하다면 경제적인 어려움이 생길 수도 있습니다.'},
      {'keyword':'투잡 쓰리잡', 'more':'다양한 수익원을 통해 돈을 벌어 부를 이룰 수 있습니다. 부동산 임대, 인세 수익과 같은 안정적인 자동 수익이 생길 가능성이 큽니다.'},
      {'keyword':'성장 중인 유망주', 'more':'재물선이 연하고 많은 사람은 다재다능하고 이것저것 배우기를 좋아하는 사람입니다. 구상하는 일이 많아 늘 바쁘지만 실질적인 성과는 내지 못하고 있는 상태입니다. 하지만 지금처럼 에너지 넘치게 도전하고 배우다 보면 재물선들이 개수는 줄어들고 선명해질 것입니다.'},
      {'keyword':'', 'more':''}
    ]
  },
  {
    id:6,
    'text': '재물선이 어디서 시작하나요?',
    'help' : '* 재물선 : 약지 밑에 있는 세로선',
    'opts' : ['운명선','두뇌선','감정선','생명선','생명선 아래','손날쪽 손바닥 윗부분', '손날쪽 손바닥 아래부분','해당없음'],
    'ans':[
      {'keyword':'보장된 성공가도', 'more':'하는 일마다 잘 되기 때문에 영업, 부동산, 스타트업 등 성과에 따라 빠르게 성장할 수 있는 곳에서 고속 승진하고 이름을 날릴 수 있습니다. 사업을 하면 사업이 번창하며, 꽃가마를 타는 결혼을 하는 경우도 있습니다.'},
      {'keyword':'아이디어 부자', 'more':'명석하고 센스가 좋아 자기만의 독창적인 아이디어를 활용해서 부와 명예를 얻게 됩니다. 사람들을 끌어모으는 능력이 있으며, 시기적으로 40대 이후에 기회가 많이 생기고 성공합니다.'},
      {'keyword':'대기만성형 부자', 'more':'청년기, 중년기는 어렵지만 50대 이후부터 세상의 인정을 받고 재물이 들어오는 대기만성형 손금입니다. 감정선 위로 운명선이나 사업선이 뻗어 있다면 노년기에도 직업 활동을 하거나 사업을 운영할 수 있습니다.'},
      {'keyword':'자수성가형 부자', 'more':'다른 사람의 도움 없이 혼자서 치열하게 노력한 끝에 성공을 거두는 강한 의지의 노력형 손금입니다. 자수성가를 통해 결국 세상의 주목을 받고 재물을 얻게 되는데, 특히 예술, 문학 방면에서 활약하는 경우가 많습니다.'},
      {'keyword':'사람들의 도움', 'more':'재산 상속이나 가업 계승, 가족의 원조 등과 같은 집안의 도움으로 성공을 거둘 수 있습니다. 집 밖에 나가도 사람들에게 인기가 많으며, 사람들이 도움을 많이 줍니다.'},
      {'keyword':'진인사대천명', 'more':'‘맨땅에 헤딩’으로 무자본이나 적은 자본으로 시작해서 부와 명예를 이룹니다. 어떤 역경도 각고의 노력을 통해 극복하며, 중간에 귀인이 나타나 도움을 줍니다.'},
      {'keyword':'사랑받는 연예인', 'more':'자기만의 독특한 세계관을 가지고 있으며, 뛰어난 예술적 감각을 기반으로 작가, 연예인, 유튜버 등 창의적인 직종에서 유명세를 얻고 성공할 수 있습니다.'},
      {'keyword':'', 'more':''}
    ]
  }
]